import React from "react"
import PageTransition from 'gatsby-plugin-page-transitions';
import Layout from "../components/layout"
import VideoCover from "../components/video-cover"
import DemandCallToAction from "../components/demand-call-to-action"
import WhoAreWe from "../components/who-are-we"
import SEO from "../components/seo"
import helpers from "../scripts/helpers"

class TransformationInnovation extends React.Component {
  render = () => (
    
      <Layout>
        <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />
        <main className="scroll-jacker">

        <VideoCover
          title="Transformation digitale et innovation."
          videoUrl="/video/digitale.mp4"
        />

        <main className="manifest">
          <section className="manifest__section manifest__section--services">
            <header className="manifest__services-header manifest__services-header--toggled"></header>
            <article className="manifest__article">
              <h2 className="manifest__article-heading">Transformation digitale et innovation.</h2>
              <h2 className="manifest__article-sub-heading">Expertise, stratégie, technologie, creative design et data science.</h2>
              <p className="manifest__para">
                Chez UTOPIE, la réussite de votre transformation digitale n'est pas un hasard. Nos consultants se basent sur
                des expertises incontestés afin d'apporter une valeur ajouté sure en terme de stratégie, technologie, creative
                design et data science.
              </p>
              <p className="manifest__para">
                <strong>
                  Aujourd'hui l'Industrie 4.0, le Cloud, la mobilité, le temps réel, l’internet des objets, le big data
                  et l’universalité d’internet redessinent les contours de l'entreprise et sa capacité à innover.
                </strong>
              </p>
              <p className="manifest__para">
                Plus proche de la perfection, les entreprises les plus matures en termes de transformation digitale sont plus
                en harmonie avec les changements et seraient 26% plus performantes que la moyenne des entreprises de leur
                secteur (Sources : Une étude sur le lien entre la transformation digitale et la performance réalisé en 2015 par
                Capgemini consulting et le MIT).
              </p>
              <p className="manifest__para">
                <strong>
                  Ces entreprises ont ainsi plus de visibilité sur l’avenir leur permettant de bien le préparer.
                </strong>
              </p>
              <p className="manifest__para">
                  Quelle a été leur stratégie digitale ?<br />
                  Quel a été l’impact du digitale sur leurs business modeles, leur fonctionnement et leurs méthodes de
                  travail ?<br />
                  Quelles initiatives digitales et quelles méthodologies ont permis d’atteindre les résultats ?<br />
              </p>
              <p className="manifest__para">
                  <strong>
                    UTOPIE vous accompagne dans une transformation profonde et accélérée des activités commerciales, des
                    processus, des compétences et des modèles afin de vous permettre de tirer pleinement parti des
                    changements et des opportunités des technologies numériques et de leur impact sur la société de manière
                    stratégique et hiérarchisée.
                  </strong>
              </p>

              <p className="manifest__para">
                <br /> <br />
              </p>

            </article>

          </section>

          <WhoAreWe />

        </main>
        
        </main>

      </Layout>
    
  )

  componentDidMount = () => { 
  }

}

export default TransformationInnovation
